import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import classNames from "classnames"

import ArrowCTA from "../../assets/svg/cta_arrow.svg"

const Quotes = ({ quotes }) => {
  const
    { t } = useTranslation('common'),
    [value, setValue] = useState(0),
    [active, setActive] = useState(false);

  let quoteClassNames = classNames({
    "TT60-thin": true,
    "TT60-thin animate-quote": active === true
  })

  const getRandomArbitrary = (min, max, value) => {
    const random = Math.round(Math.random() * (max - min) + min)

    if(value === random) {
      if(value === random && random === (quotes.length - 1)) {
        setValue(random - 1);
      }
      return setValue(random + 1);
    }
    return setValue(random)
  }

  const fortuneCookie = (min, max, value) => {
    setActive(true)
    getRandomArbitrary(min, max, value)
    setTimeout(() => {
      setActive(false)
    }, 1000);
  }

  return (
    <section data-sal='fade' data-sal-duration='750' data-sal-easing='ease' className="home--quotes">
      <q className={quoteClassNames}>{quotes[value].quote}</q>
      <p className="M16-regular author">- {quotes[value].author}</p>
      <button type="button" className="cta" onClick={() => fortuneCookie(0, quotes.length - 1, value)}>{t("cookie")}<ArrowCTA className="arrow"/></button>
    </section>
  )
}

export default Quotes
