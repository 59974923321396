import React, { useState } from "react"
import Vimeo from "@u-wave/react-vimeo"

import Image from "../image"

const Video = ({ video, className, cover, image }) => {
  const [paused, setPause] = useState(true);

  const handlePlayerPlay = () => {
    setPause(false)
  }

  const getCursorPosition = (e) => {
    let
      x = e.clientX,
      y = e.clientY;
    const root = document.documentElement;

    root.style.setProperty('--mouse-x', x + "px");
    root.style.setProperty('--mouse-y', y + "px");
  }

  return (
    <div data-sal='fade' data-sal-duration='750' data-sal-easing='ease' className={`video-container ${className}`}>
      {cover === true && paused === true &&
        <section id="videoCover" className="videoCover" onClick={handlePlayerPlay} onMouseMove={(e) => getCursorPosition(e)}>
          <p className="cursor">Play</p>
          <Image image={image.asset} alt="Latente Studio"/>
        </section>
      }
      <Vimeo
        video={video}
        paused={paused}
        onPlay={handlePlayerPlay}
        loop={true}
      />
    </div>
  )
}

export default Video
