import React, { useRef, useState, useEffect } from "react"
import { graphql } from "gatsby"
import { localize } from "../lib/helpers"

import Layout from "../components/layout"
import Seo from "../components/seo"
import BoldParagraph from "../components/boldParagraph"
import Video from "../components/Home/video"
import OpacityText from "../components/opacityText"
import Lately from "../components/Home/lately"
import HalfBlock from "../components/halfBlock"
import VideoNoControls from "../components/videoNoControls"

import Ripple from "../assets/svg/ripple.svg"
import Quotes from "../components/Home/quotes"

const IndexPage = ({ data, pageContext, location }) => {
  const
    { _rawTitle, hero, _rawHeadphones, _rawVideo, videoCover, purpose, _rawPurposeCta,
      _rawProjectsTitle, projectsText, _rawProjects, _rawProjectsCta, _rawRippleTitle, rippleText,
      _rawRippleCta, _rawBlockTitle, blockText, _rawCta, _rawBlockVideo, _rawQuotes } = localize(data.sanityHome, [pageContext.language]);

  const
    rippleRef = useRef(),
    isRippleVisible = useOnScreen(rippleRef);

    function useOnScreen(ref) {
      const [isOnScreen, setIsOnScreen] = useState(false);
      const observerRef = useRef(null);

      useEffect(() => {
        observerRef.current = new IntersectionObserver(([entry]) =>
          setIsOnScreen(entry.isIntersecting)
        );
      }, []);

      useEffect(() => {
        observerRef.current.observe(ref.current);

        return () => {
          observerRef.current.disconnect();
        };
      }, [ref]);

      return isOnScreen;
    }

  return (
    <Layout lang={pageContext.language}>
      <Seo title={_rawTitle} lang={pageContext.language} location={location}/>
      <div id="home">
        <div className="hero center">
          <BoldParagraph containerClassName="text-parent" content={hero} elemClassName="TT30-elight"/>
        </div>


        <p data-sal="fade" data-sal-duration="500" className="note">{_rawHeadphones}</p>

        <Video video={_rawVideo.vimeoVideo.url} cover={true} image={videoCover} className="home--film"/>

        <OpacityText content={purpose} cta={_rawPurposeCta} containerClassName="home--purpose"/>

        <Lately title={_rawProjectsTitle} text={projectsText} projects={_rawProjects} cta={_rawProjectsCta}/>

        <div className="home--process-ripple">
          <div className="ripple-container center" ref={rippleRef}>
            <Ripple className={isRippleVisible ? "ripple animate" : "ripple"}/>
          </div>
          <HalfBlock title={_rawRippleTitle} text={rippleText} cta={_rawRippleCta}/>
        </div>

        <div className="home--us--video">
          <HalfBlock title={_rawBlockTitle} text={blockText} cta={_rawCta}/>
          <VideoNoControls video={_rawBlockVideo.vimeoVideo.oEmbedData.html}/>
        </div>

        <Quotes quotes={_rawQuotes}/>
      </div>


    </Layout>
  )
}

export const query = graphql`
  query HomePage($language: String) {
    sanityHome {
      _rawTitle
      hero {
        _key
        translate(language: $language)
        isBold
      }
      _rawHeadphones
      _rawVideo
      videoCover {
        asset {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      purpose {
        _key
        translate(language: $language)
        isBold
      }
      _rawPurposeCta
      _rawProjectsTitle
      projectsText {
        translate(language: $language)
        isBold
      }
      _rawProjects(resolveReferences: {maxDepth: 5})
      projects {
        _id
        title
        _rawSlug
        _rawLiner
        covers {
          asset {
            gatsbyImageData(layout: CONSTRAINED, width: 980)
          }
        }
        category
      }
      _rawProjectsCta
      _rawRippleTitle
      rippleText {
        translate(language: $language)
        isBold
      }
      _rawRippleCta
      _rawBlockTitle
      blockText {
        translate(language: $language)
        isBold
      }
      _rawCta
      _rawBlockVideo
      _rawQuotes
    }
  }
`

export default IndexPage
