import React, { useState } from "react"
import classNames  from "classnames";

import BoldParagraph from "../boldParagraph"
import Project from "./project";
import Cta from "../cta"

import ArrowLeft from "../../assets/svg/arrow-left.svg"
import ArrowRight from "../../assets/svg/arrow-right.svg"

const Lately = ({ title, text, projects, cta }) => {
  const [value, setValue] = useState(0)
  const [mobileValue, setMobileValue] = useState(0)

  let
    MobileSliderClasses = classNames({
      'slide': true,
      'slide-two': mobileValue === 1,
      'slide-three': mobileValue === 2,
      'slide-four': mobileValue === 3
    });

  const moveBack = () => {
    setValue(value - 2)

    if (value === 0) {
      setValue(2)
    }
  }

  const moveForward = () => {
    setValue(value + 2)

    if (value === 2) {
      setValue(0)
    }
  }

  const moveBackMobile = () => {
    setMobileValue(mobileValue - 1)

    if (mobileValue === 0) {
      setMobileValue(3)
    }
  }

  const moveForwardMobile = () => {
    setMobileValue(mobileValue + 1)

    if (mobileValue === 3) {
      setMobileValue(0)
    }
  }

  return (
    <section className="grid-16 home--lately">
      <h2 data-sal='fade' data-sal-duration='800' data-sal-easing='ease' className="M60-regular">{title}</h2>
      <BoldParagraph content={text} elemClassName="M24-light" containerClassName="lately-description"/>

      <button type="button" className="arrow-slide" onClick={moveBack} disabled={value === 0}><ArrowLeft /></button>

      <div data-sal='fade' data-sal-duration='750' data-sal-easing='ease' className="slider-projects">
        <div className={`slide  ${value === 2 ? 'animateForward' : 'animateBackwards'}`}>
          {projects?.map(project => (
            <Project key={project._id} project={project}/>
          ))}
        </div>
      </div>

      <div className="mobile-slider-container grid-16">
        <div className="controls-container">
          <button type="button" className="arrow-slide mobile" onClick={moveBackMobile} disabled={mobileValue === 0}><ArrowLeft /></button>
          <span>{mobileValue + 1}/4</span>
          <button type="button" className="arrow-slide right mobile" onClick={moveForwardMobile} disabled={mobileValue === 3}><ArrowRight/></button>
        </div>

        <div data-sal='fade' data-sal-duration='750' data-sal-easing='ease' className="slider-projects mobile">
          <div className={MobileSliderClasses}>
            {projects?.map(project => (
              <Project key={project._id} project={project}/>
            ))}
          </div>
        </div>
      </div>


      <button type="button" className="arrow-slide right" onClick={moveForward} disabled={value === 2}><ArrowRight/></button>

      <Cta cta={cta}/>
    </section>
  )
}

export default Lately
