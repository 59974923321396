import React from "react"
import { Link } from "gatsby"
import { useTranslation } from "react-i18next"

import Image from "../Project/Image"

const Project = ({ project: { title, covers, liner, category, slug }}) => {
  const { i18n } = useTranslation('common')

  return(
    <div className={`project-container`}>
      <Link to={`/${i18n.language}/${slug?.current}`} className="border covers">
        {covers?.map((cover, i) => (
          <Image key={`image-lately-${i*6}`} className={`img-cont c-${i}`} image={cover} alt={`${title} | Latente Studio`}/>
        ))
        }
      </Link>

      <div className="info">
        <div className="left-container">
          <h6>{title}</h6>
          <p>{liner}</p>
        </div>

        <ul>
          {category?.map((cat, i) => (
            <li key={`li-proj-${i*3}`}>{cat}</li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default Project
